import {proxyURL} from "../../../services/office-api";
import {Show} from "solid-js";

export default function HeaderLeft(props) {
    return (
        <a href={proxyURL(props.token, "left-menu|home")}>
            <Show when={props.whiteLabel} fallback={
                <i class="header-left-logo"
                   innerHTML={require("bundle-text:../assets/builderall.svg")}/>
            }>
                <i class="header-left-logo">
                    <img src={props.whiteLabel.logo}/>
                </i>
            </Show>
            <div class="header-left-title">
                <Show when={props.whiteLabel} fallback={<span>Builderall</span>}>
                    <span>{props.whiteLabel.title}</span>
                </Show>
                {/*<i class="header-left-beta" innerHTML={require("bundle-text:../assets/beta.svg")}/>*/}
            </div>
        </a>
    );
}