import {customElement} from "solid-element";
import {createMemo, For} from "solid-js";
import {proxyURL} from "../../services/office-api";
import {translations} from "../../services/context";

customElement("builderall-footer", {token: ""}, props => {
    const items = createMemo(() => [
        "terms-of-use",
        "privacy-policy",
        "franchise-system",
        "support",
    ].map(slug => ({
        title: translations[`footer-menu--${slug}--title`],
        href: proxyURL(props.token, `footer-menu|${slug}`),
    })));


    return (
        <>
            <style>{require("bundle-text:./styles/index.scss")}</style>
            <footer class="footer">
                <span class="footer__item">© Builderall</span>
                <nav class="footer__item footer__item--nav">
                    <For each={items()}>
                        {({title, href}) => (
                            <>
                                <span>|</span>
                                <a class="footer__nav__item" href={href} target="_blank">{title}</a>
                            </>
                        )}
                    </For>
                </nav>
            </footer>
        </>
    );
});