import {translations} from "../../../services/context";

export default function SendMessageToSponsorModal(props) {
    return (
        <div class="modal-send-mensage-to-sponsor">
            <div class="modal-header-send-mensage-to-sponsor">
                <div class="send-message-to-sponsor-header">
                <span class="modal-title-send-message-to-sponsor">{translations["avatar-send-a-message-to-your-sponsor"]}</span>
                <hr class="modal-send-message-to-sponsor-line" />
                </div>
                <button class="modal-close-btn" id="btn-close-modal-enom">
                     <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" class="modal-close-svg" viewBox="0 0 23 23" fill="none">
                         <g clip-path="url(#clip0_174_1943)">
                            <path d="M11.5645 0.5C5.52478 0.5 0.628906 5.4247 0.628906 11.5C0.628906 17.5753 5.52478 22.5 11.5645 22.5C17.6043 22.5 22.5001 17.5753 22.5001 11.5C22.5001 5.4247 17.6043 0.5 11.5645 0.5ZM15.6987 14.4915C16.0191 14.8138 16.0191 15.3357 15.6987 15.658C15.5385 15.8197 15.3286 15.9 15.1186 15.9C14.9086 15.9 14.6987 15.8197 14.5385 15.6586L11.5645 12.6666L8.59058 15.658C8.43037 15.8197 8.22041 15.9 8.01044 15.9C7.80048 15.9 7.59051 15.8197 7.43031 15.6586C7.10989 15.3363 7.10989 14.8143 7.43031 14.492L10.4048 11.5L7.43086 8.50855C7.11044 8.18625 7.11044 7.6643 7.43086 7.342C7.75127 7.0197 8.27016 7.0197 8.59058 7.342L11.5645 10.3335L14.5385 7.342C14.8589 7.0197 15.3778 7.0197 15.6982 7.342C16.0186 7.6643 16.0186 8.18625 15.6982 8.50855L12.7242 11.5L15.6987 14.4915Z" fill="#95ACCC" class="modal-close-svg"/>
                        </g>
                         <defs>
                            <clipPath id="clip0_174_1943">
                            <rect width="21.8712" height="22" fill="white" transform="translate(0.628906 0.5)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
            <div class="modal-content">
                <label class="modal-content-send-mesasge-to-sponsor-label">{translations["avatar-type-your-message-below"]}</label>
                <textarea class="modal-content-textarea" id="modalContentTextareaMessage" placeholder={translations['avatar-enter-message-textarea'] + ['...']} cols="30" rows="10"></textarea>
            </div>
            <div class="modal-footer">
                <button class="modal-footer-btn send-message-to-sponsor-btn ba-disabled" disabled={true} id="send-message-to-sponsot-close-btn">{translations["avatar-send-message-to-sponsor-send-btn"]}</button>
            </div>
        </div>
    );
}