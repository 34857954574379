export const countryCodes = [
    {'country':'us', 'name':'united states', 'code':'1'},
    {'country':'br', 'name':'brazil', 'code':'55'},
    {'country':'ad', 'name':'andorra', 'code':'376'},
    {'country':'ae', 'name':'united arab emirates', 'code':'971'},
    {'country':'af', 'name':'afghanistan', 'code':'93'},
    {'country':'ag', 'name':'antigua and barbuda', 'code':'1268'},
    {'country':'ai', 'name':'anguilla', 'code':'1264'},
    {'country':'al', 'name':'albania', 'code':'355'},
    {'country':'am', 'name':'armenia', 'code':'374'},
    {'country':'an', 'name':'netherlands antilles', 'code':'599'},
    {'country':'ao', 'name':'angola', 'code':'244'},
    {'country':'aq', 'name':'antarctica', 'code':'672'},
    {'country':'ar', 'name':'argentina', 'code':'54'},
    {'country':'as', 'name':'american samoa', 'code':'1684'},
    {'country':'at', 'name':'austria', 'code':'43'},
    {'country':'au', 'name':'australia', 'code':'61'},
    {'country':'aw', 'name':'aruba', 'code':'297'},
    {'country':'az', 'name':'azerbaijan', 'code':'994'},
    {'country':'ba', 'name':'bosnia and herzegovina', 'code':'387'},
    {'country':'bb', 'name':'barbados', 'code':'1246'},
    {'country':'bd', 'name':'bangladesh', 'code':'880'},
    {'country':'be', 'name':'belgium', 'code':'32'},
    {'country':'bf', 'name':'burkina faso', 'code':'226'},
    {'country':'bg', 'name':'bulgaria', 'code':'359'},
    {'country':'bh', 'name':'bahrain', 'code':'973'},
    {'country':'bi', 'name':'burundi', 'code':'257'},
    {'country':'bj', 'name':'benin', 'code':'229'},
    {'country':'bl', 'name':'saint barthelemy', 'code':'590'},
    {'country':'bm', 'name':'bermuda', 'code':'1441'},
    {'country':'bn', 'name':'brunei darussalam', 'code':'673'},
    {'country':'bo', 'name':'bolivia', 'code':'591'},
    {'country':'bs', 'name':'bahamas', 'code':'1242'},
    {'country':'bt', 'name':'bhutan', 'code':'975'},
    {'country':'bw', 'name':'botswana', 'code':'267'},
    {'country':'by', 'name':'belarus', 'code':'375'},
    {'country':'bz', 'name':'belize', 'code':'501'},
    {'country':'ca', 'name':'canada', 'code':'1'},
    {'country':'cc', 'name':'cocos (keeling) islands', 'code':'61'},
    {'country':'cd', 'name':'congo, the democratic republic of the', 'code':'243'},
    {'country':'cf', 'name':'central african republic', 'code':'236'},
    {'country':'cg', 'name':'congo', 'code':'242'},
    {'country':'ch', 'name':'switzerland', 'code':'41'},
    {'country':'ci', 'name':'cote d ivoire', 'code':'225'},
    {'country':'ck', 'name':'cook islands', 'code':'682'},
    {'country':'cl', 'name':'chile', 'code':'56'},
    {'country':'cm', 'name':'cameroon', 'code':'237'},
    {'country':'cn', 'name':'china', 'code':'86'},
    {'country':'co', 'name':'colombia', 'code':'57'},
    {'country':'cr', 'name':'costa rica', 'code':'506'},
    {'country':'cu', 'name':'cuba', 'code':'53'},
    {'country':'cv', 'name':'cape verde', 'code':'238'},
    {'country':'cx', 'name':'christmas island', 'code':'61'},
    {'country':'cy', 'name':'cyprus', 'code':'357'},
    {'country':'cz', 'name':'czech republic', 'code':'420'},
    {'country':'de', 'name':'germany', 'code':'49'},
    {'country':'dj', 'name':'djibouti', 'code':'253'},
    {'country':'dk', 'name':'denmark', 'code':'45'},
    {'country':'dm', 'name':'dominica', 'code':'1767'},
    {'country':'do', 'name':'dominican republic', 'code':'1809'},
    {'country':'dz', 'name':'algeria', 'code':'213'},
    {'country':'ec', 'name':'ecuador', 'code':'593'},
    {'country':'ee', 'name':'estonia', 'code':'372'},
    {'country':'eg', 'name':'egypt', 'code':'20'},
    {'country':'er', 'name':'eritrea', 'code':'291'},
    {'country':'es', 'name':'spain', 'code':'34'},
    {'country':'et', 'name':'ethiopia', 'code':'251'},
    {'country':'fi', 'name':'finland', 'code':'358'},
    {'country':'fj', 'name':'fiji', 'code':'679'},
    {'country':'fk', 'name':'falkland islands (malvinas)' ,'code':'500'},
    {'country':'fm', 'name':'micronesia, federated states of', 'code':'691'},
    {'country':'fo', 'name':'faroe islands', 'code':'298'},
    {'country':'fr', 'name':'france', 'code':'33'},
    {'country':'ga', 'name':'gabon', 'code':'241'},
    {'country':'gb', 'name':'united kingdom', 'code':'44'},
    {'country':'gd', 'name':'grenada', 'code':'1473'},
    {'country':'ge', 'name':'georgia', 'code':'995'},
    {'country':'gh', 'name':'ghana', 'code':'233'},
    {'country':'gi', 'name':'gibraltar', 'code':'350'},
    {'country':'gl', 'name':'greenland', 'code':'299'},
    {'country':'gm', 'name':'gambia', 'code':'220'},
    {'country':'gn', 'name':'guinea', 'code':'224'},
    {'country':'gq', 'name':'equatorial guinea', 'code':'240'},
    {'country':'gr', 'name':'greece', 'code':'30'},
    {'country':'gt', 'name':'guatemala', 'code':'502'},
    {'country':'gu', 'name':'guam', 'code':'1671'},
    {'country':'gw', 'name':'guinea-bissau', 'code':'245'},
    {'country':'gy', 'name':'guyana', 'code':'592'},
    {'country':'hk', 'name':'hong kong', 'code':'852'},
    {'country':'hn', 'name':'honduras', 'code':'504'},
    {'country':'hr', 'name':'croatia', 'code':'385'},
    {'country':'ht', 'name':'haiti', 'code':'509'},
    {'country':'hu', 'name':'hungary', 'code':'36'},
    {'country':'id', 'name':'indonesia', 'code':'62'},
    {'country':'ie', 'name':'ireland', 'code':'353'},
    {'country':'il', 'name':'israel', 'code':'972'},
    {'country':'im', 'name':'isle of man', 'code':'44'},
    {'country':'in', 'name':'india', 'code':'91'},
    {'country':'iq', 'name':'iraq', 'code':'964'},
    {'country':'ir', 'name':'iran, islamic republic of', 'code':'98'},
    {'country':'is', 'name':'iceland', 'code':'354'},
    {'country':'it', 'name':'italy', 'code':'39'},
    {'country':'jm', 'name':'jamaica', 'code':'1876'},
    {'country':'jo', 'name':'jordan', 'code':'962'},
    {'country':'jp', 'name':'japan', 'code':'81'},
    {'country':'ke', 'name':'kenya', 'code':'254'},
    {'country':'kg', 'name':'kyrgyzstan', 'code':'996'},
    {'country':'kh', 'name':'cambodia', 'code':'855'},
    {'country':'ki', 'name':'kiribati', 'code':'686'},
    {'country':'km', 'name':'comoros', 'code':'269'},
    {'country':'kn', 'name':'saint kitts and nevis', 'code':'1869'},
    {'country':'kp', 'name':'korea democratic peoples republic of', 'code':'850'},
    {'country':'kr', 'name':'korea republic of', 'code':'82'},
    {'country':'kw', 'name':'kuwait', 'code':'965'},
    {'country':'ky', 'name':'cayman islands', 'code':'1345'},
    {'country':'kz', 'name':'kazakstan', 'code':'7'},
    {'country':'la', 'name':'lao peoples democratic republic', 'code':'856'},
    {'country':'lb', 'name':'lebanon', 'code':'961'},
    {'country':'lc', 'name':'saint lucia', 'code':'1758'},
    {'country':'li', 'name':'liechtenstein', 'code':'423'},
    {'country':'lk', 'name':'sri lanka', 'code':'94'},
    {'country':'lr', 'name':'liberia', 'code':'231'},
    {'country':'ls', 'name':'lesotho', 'code':'266'},
    {'country':'lt', 'name':'lithuania', 'code':'370'},
    {'country':'lu', 'name':'luxembourg', 'code':'352'},
    {'country':'lv', 'name':'latvia', 'code':'371'},
    {'country':'ly', 'name':'libyan arab jamahiriya', 'code':'218'},
    {'country':'ma', 'name':'morocco', 'code':'212'},
    {'country':'mc', 'name':'monaco', 'code':'377'},
    {'country':'md', 'name':'moldova, republic of', 'code':'373'},
    {'country':'me', 'name':'montenegro', 'code':'382'},
    {'country':'mf', 'name':'saint martin', 'code':'1599'},
    {'country':'mg', 'name':'madagascar', 'code':'261'},
    {'country':'mh', 'name':'marshall islands', 'code':'692'},
    {'country':'mk', 'name':'macedonia, the former yugoslav republic of', 'code':'389'},
    {'country':'ml', 'name':'mali', 'code':'223'},
    {'country':'mm', 'name':'myanmar', 'code':'95'},
    {'country':'mn', 'name':'mongolia', 'code':'976'},
    {'country':'mo', 'name':'macau', 'code':'853'},
    {'country':'mp', 'name':'northern mariana islands', 'code':'1670'},
    {'country':'mr', 'name':'mauritania', 'code':'222'},
    {'country':'ms', 'name':'montserrat', 'code':'1664'},
    {'country':'mt', 'name':'malta', 'code':'356'},
    {'country':'mu', 'name':'mauritius', 'code':'230'},
    {'country':'mv', 'name':'maldives', 'code':'960'},
    {'country':'mw', 'name':'malawi', 'code':'265'},
    {'country':'mx', 'name':'mexico', 'code':'52'},
    {'country':'my', 'name':'malaysia', 'code':'60'},
    {'country':'mz', 'name':'mozambique', 'code':'258'},
    {'country':'na', 'name':'namibia', 'code':'264'},
    {'country':'nc', 'name':'new caledonia', 'code':'687'},
    {'country':'ne', 'name':'niger', 'code':'227'},
    {'country':'ng', 'name':'nigeria', 'code':'234'},
    {'country':'ni', 'name':'nicaragua', 'code':'505'},
    {'country':'nl', 'name':'netherlands', 'code':'31'},
    {'country':'no', 'name':'norway', 'code':'47'},
    {'country':'np', 'name':'nepal', 'code':'977'},
    {'country':'nr', 'name':'nauru', 'code':'674'},
    {'country':'nu', 'name':'niue', 'code':'683'},
    {'country':'nz', 'name':'new zealand', 'code':'64'},
    {'country':'om', 'name':'oman', 'code':'968'},
    {'country':'pa', 'name':'panama', 'code':'507'},
    {'country':'pe', 'name':'peru', 'code':'51'},
    {'country':'pf', 'name':'french polynesia', 'code':'689'},
    {'country':'pg', 'name':'papua new guinea', 'code':'675'},
    {'country':'ph', 'name':'philippines', 'code':'63'},
    {'country':'pk', 'name':'pakistan', 'code':'92'},
    {'country':'pl', 'name':'poland', 'code':'48'},
    {'country':'pm', 'name':'saint pierre and miquelon', 'code':'508'},
    {'country':'pn', 'name':'pitcairn', 'code':'870'},
    {'country':'pr', 'name':'puerto rico', 'code':'1'},
    {'country':'pt', 'name':'portugal', 'code':'351'},
    {'country':'pw', 'name':'palau', 'code':'680'},
    {'country':'py', 'name':'paraguay', 'code':'595'},
    {'country':'qa', 'name':'qatar', 'code':'974'},
    {'country':'ro', 'name':'romania', 'code':'40'},
    {'country':'rs', 'name':'serbia', 'code':'381'},
    {'country':'ru', 'name':'russian federation', 'code':'7'},
    {'country':'rw', 'name':'rwanda', 'code':'250'},
    {'country':'sa', 'name':'saudi arabia', 'code':'966'},
    {'country':'sb', 'name':'solomon islands', 'code':'677'},
    {'country':'sc', 'name':'seychelles', 'code':'248'},
    {'country':'sd', 'name':'sudan', 'code':'249'},
    {'country':'se', 'name':'sweden', 'code':'46'},
    {'country':'sg', 'name':'singapore', 'code':'65'},
    {'country':'sh', 'name':'saint helena', 'code':'290'},
    {'country':'si', 'name':'slovenia', 'code':'386'},
    {'country':'sk', 'name':'slovakia', 'code':'421'},
    {'country':'sl', 'name':'sierra leone', 'code':'232'},
    {'country':'sm', 'name':'san marino', 'code':'378'},
    {'country':'sn', 'name':'senegal', 'code':'221'},
    {'country':'so', 'name':'somalia', 'code':'252'},
    {'country':'sr', 'name':'suriname', 'code':'597'},
    {'country':'st', 'name':'sao tome and principe', 'code':'239'},
    {'country':'sv', 'name':'el salvador', 'code':'503'},
    {'country':'sy', 'name':'syrian arab republic', 'code':'963'},
    {'country':'sz', 'name':'swaziland', 'code':'268'},
    {'country':'tc', 'name':'turks and caicos islands', 'code':'1649'},
    {'country':'td', 'name':'chad', 'code':'235'},
    {'country':'tg', 'name':'togo', 'code':'228'},
    {'country':'th', 'name':'thailand', 'code':'66'},
    {'country':'tj', 'name':'tajikistan', 'code':'992'},
    {'country':'tk', 'name':'tokelau', 'code':'690'},
    {'country':'tl', 'name':'timor-leste', 'code':'670'},
    {'country':'tm', 'name':'turkmenistan', 'code':'993'},
    {'country':'tn', 'name':'tunisia', 'code':'216'},
    {'country':'to', 'name':'tonga', 'code':'676'},
    {'country':'tr', 'name':'turkey', 'code':'90'},
    {'country':'tt', 'name':'trinidad and tobago', 'code':'1868'},
    {'country':'tv', 'name':'tuvalu', 'code':'688'},
    {'country':'tw', 'name':'taiwan, province of china', 'code':'886'},
    {'country':'tz', 'name':'tanzania, united republic of', 'code':'255'},
    {'country':'ua', 'name':'ukraine', 'code':'380'},
    {'country':'ug', 'name':'uganda', 'code':'256'},
    {'country':'uy', 'name':'uruguay', 'code':'598'},
    {'country':'uz', 'name':'uzbekistan', 'code':'998'},
    {'country':'va', 'name':'holy see (vatican city state)' ,'code':'39'},
    {'country':'vc', 'name':'saint vincent and the grenadines', 'code':'1784'},
    {'country':'ve', 'name':'venezuela', 'code':'58'},
    {'country':'vg', 'name':'virgin islands, british', 'code':'1284'},
    {'country':'vi', 'name':'virgin islands, u.s.' ,'code':'1340'},
    {'country':'vn', 'name':'viet nam', 'code':'84'},
    {'country':'vu', 'name':'vanuatu', 'code':'678'},
    {'country':'wf', 'name':'wallis and futuna', 'code':'681'},
    {'country':'ws', 'name':'samoa', 'code':'685'},
    {'country':'xk', 'name':'kosovo', 'code':'381'},
    {'country':'ye', 'name':'yemen', 'code':'967'},
    {'country':'yt', 'name':'mayotte', 'code':'262'},
    {'country':'za', 'name':'south africa', 'code':'27'},
    {'country':'zm', 'name':'zambia', 'code':'260'},
    {'country':'zw', 'name':'zimbabwe','code':'263'}
]