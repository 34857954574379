export const groups = {
    "builders": {
        // @ts-ignore
        background: new URL("../assets/builders.png?as=webp", import.meta.url),
        color: "#ee8d00",
    },
    "email-and-engagement": {
        // @ts-ignore
        background: new URL("../assets/email-and-engagement.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
    "design-and-video": {
        // @ts-ignore
        background: new URL("../assets/video-and-design.png?as=webp", import.meta.url),
        color: "#7d31e5",
    },
    "business-center": {
        // @ts-ignore
        background: new URL("../assets/business-center.png?as=webp", import.meta.url),
        color: "#01d4b9",
    },
    "franchise": {
        // @ts-ignore
        background: new URL("../assets/associate.png?as=webp", import.meta.url),
        color: "#ffffff",
    },
    "livechat": {
        // @ts-ignore
        background: new URL("../assets/livechat.png?as=webp", import.meta.url),
        color: "#01d4b9",
    },
    "browser-notification": {
        // @ts-ignore
        background: new URL("../assets/browser-notification.png?as=webp", import.meta.url),
        color: "#ee8d00",
    },
    "meta-comment-autoresponder": {
        // @ts-ignore
        background: new URL("../assets/meta-comment-autoresponder.png?as=webp", import.meta.url),
        color: "#01d4b9",
    },
    "exit-popup": {
        // @ts-ignore
        background: new URL("../assets/exit-popup.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
    "funnel-builder": {
        // @ts-ignore
        background: new URL("../assets/funnel-builder.png?as=webp", import.meta.url),
        color: "#ee8d00",
    },
    "website-builder": {
        // @ts-ignore
        background: new URL("../assets/website-builder.png?as=webp", import.meta.url),
        color: "#ee8d00",
    },
    "booking": {
        // @ts-ignore
        background: new URL("../assets/booking.png?as=webp", import.meta.url),
        color: "#ee8d00",
    },
    "elearning": {
        // @ts-ignore
        background: new URL("../assets/elearning.png?as=webp", import.meta.url),
        color: "#ee8d00",
    },
    "email-marketing-5": {
        // @ts-ignore
        background: new URL("../assets/email-marketing-5.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
    "social-proof-pop-up": {
        // @ts-ignore
        background: new URL("../assets/social-proof-pop-up.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
    "general_tools": {
        // @ts-ignore
        background: new URL("../assets/tools_general.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
};
