import { register } from "component-register";
export { hot, getCurrentElement, noShadowDOM } from "component-register";
import { createRoot, createSignal } from "solid-js";
import { insert } from "solid-js/web";
function createProps(raw) {
    const keys = Object.keys(raw);
    const props = {};
    for (let i = 0; i < keys.length; i++) {
        const [get, set] = createSignal(raw[keys[i]]);
        Object.defineProperty(props, keys[i], {
            get,
            set(v) {
                set(() => v);
            }
        });
    }
    return props;
}
function lookupContext(el) {
    if (el.assignedSlot && el.assignedSlot._$owner)
        return el.assignedSlot._$owner;
    let next = el.parentNode;
    while (next &&
        !next._$owner &&
        !(next.assignedSlot && next.assignedSlot._$owner))
        next = next.parentNode;
    return next && next.assignedSlot
        ? next.assignedSlot._$owner
        : el._$owner;
}
function withSolid(ComponentType) {
    return (rawProps, options) => {
        const { element } = options;
        return createRoot((dispose) => {
            const props = createProps(rawProps);
            element.addPropertyChangedCallback((key, val) => (props[key] = val));
            element.addReleaseCallback(() => {
                element.renderRoot.textContent = "";
                dispose();
            });
            const comp = ComponentType(props, options);
            return insert(element.renderRoot, comp);
        }, lookupContext(element));
    };
}
function customElement(tag, props, ComponentType) {
    if (arguments.length === 2) {
        ComponentType = props;
        props = {};
    }
    return register(tag, props)(withSolid(ComponentType));
}
export { withSolid, customElement };
