import {customElement} from "solid-element";
// @ts-ignore
import image from "./assets/empty-data.png";

customElement("builderall-empty-image", {size: '100%'}, (props, {element}) => {
    return (
        <>
            <img src={image} width={props.size} />
        </>
    );
});