export const tools = {
    "website-builder": {
        icon: require("bundle-text:./assets/website-builder.svg"),
        img: require("url:./assets/website-builder.png"),
        group: "website-builder",
    },
    "booking": {
        icon: require("bundle-text:./assets/booking.svg"),
        img: require("url:./assets/booking.png"),
        group: "booking",
    },
    "elearning": {
        icon: require("bundle-text:./assets/elearning.svg"),
        img: require("url:./assets/elearning.png"),
        group: "elearning",
    },
    "webinar": {
        icon: require("bundle-text:./assets/webinar.svg"),
        img: require("url:./assets/webinar.png"),
        group: "general_tools",
    },
    "quiz": {
        icon: require("bundle-text:./assets/quiz.svg"),
        img: require("url:./assets/quiz.png"),
        group: "general_tools",
    },
    "wordpress": {
        icon: require("bundle-text:./assets/wordpress.svg"),
        img: require("url:./assets/wordpress.png"),
        group: "general_tools",
    },
    "email-marketing-5": {
        icon: require("bundle-text:./assets/email-marketing-5.svg"),
        img: require("url:./assets/email-marketing-5.png"),
        group: "email-marketing-5",
    },
    "whatsapp-automation": {
        icon: require("bundle-text:./assets/whatsapp-automation.svg"),
        img: require("url:./assets/whatsapp-automation.png"),
        group: "general_tools",
    },
    "whatsapp-launch-manager": {
        icon: require("bundle-text:./assets/whatsapp-launch-manager.svg"),
        img: require("url:./assets/whatsapp-launch-manager.png"),
        group: "general_tools",
    },
    "whatsapp-central": {
        icon: require("bundle-text:./assets/whatsapp-central.svg"),
        group: "general_tools",
    },
    "telegram": {
        icon: require("bundle-text:./assets/telegram.svg"),
        img: require("url:./assets/telegram.png"),
        group: "general_tools",
    },
    "sms": {
        icon: require("bundle-text:./assets/sms.svg"),
        img: require("url:./assets/sms.png"),
        group: "general_tools",
    },
    "messenger-chatbot": {
        icon: require("bundle-text:./assets/messenger-chatbot.svg"),
        img: require("url:./assets/messenger-chatbot.png"),
        group: "general_tools",
    },
    "website-chatbot": {
        icon: require("bundle-text:./assets/website-chatbot.svg"),
        img: require("url:./assets/website-chatbot.png"),
        group: "general_tools",
    },
    "social-autopost": {
        icon: require("bundle-text:./assets/social-autopost.svg"),
        img: require("url:./assets/social-autopost.png"),
        group: "general_tools",
    },
    "social-proof-pop-up": {
        icon: require("bundle-text:./assets/social-proof-pop-up.svg"),
        img: require("url:./assets/social-proof-pop-up.png"),
        group: "social-proof-pop-up",
    },
    "browser-notification": {
        icon: require("bundle-text:./assets/browser-notification.svg"),
        img: require("url:./assets/browser-notification.png"),
        group: "browser-notification",
    },
    "clickmap": {
        icon: require("bundle-text:./assets/clickmap.svg"),
        img: require("url:./assets/clickmap.png"),
        group: "general_tools",
    },
    "helpdesk": {
        icon: require("bundle-text:./assets/helpdesk.svg"),
        img: require("url:./assets/helpdesk.png"),
        group: "general_tools",
    },
    "magazine-builder": {
        icon: require("bundle-text:./assets/plume.svg"),
        img: require("url:./assets/plume.png"),
        group: "general_tools",
    },
    "branding-designs": {
        icon: require("bundle-text:./assets/mockup-studio.svg"),
        img: require("url:./assets/mockup-studio.png"),
        group: "general_tools",
    },
    "video-hosting": {
        icon: require("bundle-text:./assets/video-hosting.svg"),
        img: require("url:./assets/video-hosting.png"),
        group: "general_tools",
    },
    "3d-photo-editor-studio": {
        icon: require("bundle-text:./assets/3d-photo-editor-studio.svg"),
        img: require("url:./assets/3d-photo-editor-studio.png"),
        group: "general_tools",
    },
    "crm": {
        icon: require("bundle-text:./assets/crm.svg"),
        img: require("url:./assets/crm.png"),
        group: "general_tools",
    },
    "website-agency": {
        icon: require("bundle-text:./assets/website-agency.svg"),
        img: require("url:./assets/website-agency.png"),
        group: "general_tools",
    },
    "seo-report": {
        icon: require("bundle-text:./assets/seo-report.svg"),
        group: "general_tools",
    },
    "url-shortener": {
        icon: require("bundle-text:./assets/url-shortener.svg"),
        group: "general_tools",
    },
    "dns-manager": {
        icon: require("bundle-text:./assets/dns-manager.svg"),
        img: require("url:./assets/dns-manager.png"),
        group: "general_tools",
    },
    "team-access": {
        icon: require("bundle-text:./assets/team-access.svg"),
        group: "general_tools",
    },
    "funnel-builder": {
        icon: require("bundle-text:./assets/funnel-builder.svg"),
        img: require("url:./assets/funnel-builder.png"),
        group: "funnel-builder",
    },
    "office": {
        icon: require("bundle-text:./assets/user.svg"),
        group: "general_tools",
    },
    "franchise-system": {
        icon: require("bundle-text:./assets/associate.svg"),
        img: require("url:./assets/associate.png"),
        group: "general_tools",
    },
    "livechat": {
      icon: require("bundle-text:./assets/ic_live-chat.svg"),
      img: require("url:./assets/messenger-chatbot.png"),
      group: "livechat",
    },
    "exit-popup": {
      icon: require("bundle-text:./assets/website-builder.svg"),
      img: require("url:./assets/website-builder.png"),
      group: "exit-popup",
    },
    "meta-comment-autoresponder": {
      icon: require("bundle-text:./assets/messenger-chatbot.svg"),
      img: require("url:./assets/messenger-chatbot.png"),
      group: "meta-comment-autoresponder",
    }
};
