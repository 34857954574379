import {customElement} from "solid-element";
import {createMemo} from "solid-js";
import {proxyURL} from "../../services/office-api";
import {translations} from "../../services/context";

customElement("builderall-help", {token: "", collapsed: false}, props => {
    const url = createMemo(() => proxyURL(props.token, "general-stuff|help"));

    return (
        <>
            <style>{require("bundle-text:./styles/index.scss")}</style>
            <a class="help" classList={{"help--collapsed": props.collapsed}}
               href={url()} target="_self">
                <div class="help__icon" innerHTML={require("bundle-text:./assets/help.svg")}/>
                <div class="help__title">{translations["general-stuff--help--title"]}</div>
            </a>
        </>
    );
});