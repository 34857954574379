import {For, Show} from "solid-js";
import {translations} from "../../../services/context";

export default function FacebookCommunityModal(props: {
    communities: {
        title: string,
        description: string,
        href: string,
    }[]
}) {
    return (
        <div class="modal">
            <div class="modal-header">
                <div>
                    <div innerHTML={require("bundle-text:../assets/meta.svg")}/>
                    <h2 class="modal-title">{translations["top-menu--connect--community--facebook--title"]}</h2>
                </div>
                <button class="close-btn" innerHTML={require("bundle-text:../assets/close.svg")}/>
            </div>
            <div class="modal-content">
                <For each={props.communities}>
                    {(item, index) => (
                        <>
                            <Show when={index() > 0} children={<hr/>}/>
                            <div class="content-item">
                                <i innerHTML={require("bundle-text:../assets/builderall.svg")}/>
                                <div class="item-info">
                                    <p>{item.title}</p>
                                    <span>{item.description}</span>
                                    <a href={item.href} target="_blank">
                                        {translations["top-menu--connect--community--facebook--join-now"]}
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </For>
            </div>
        </div>
    );
}