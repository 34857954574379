import {customElement} from "solid-element";
import {createEffect, createSignal, Show} from "solid-js";
import {translations} from "../../services/context";

customElement("builderall-global-script", {token: "", site: ""}, props => {
  const [source, setSource] = createSignal("");
  const [installed, setInstalled] = createSignal(false);
  const [iconCode, setIconCode] = createSignal("f5ff");

  createEffect(async () => {
    if (!props.token) return;
    const url = `${process.env.GLOBAL_SCRIPT}/api/get-script-by-token/${props.token}`;
    const res = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    const {data: {script}} = await res.json();
    setSource(script);
  });

  createEffect(async () => {
    if (!props.token || !props.site) return;
    const url = `${process.env.GLOBAL_SCRIPT}/api/check-script-by-site?site=${props.site}`;
    const res = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": `Bearer ${props.token}`,
      },
    });
    const {data: {enabled}} = await res.json();
    setInstalled(enabled);
  });

  async function copyScript() {
    await navigator.clipboard.writeText(source());
    setIconCode("ad00");
    setTimeout(() => setIconCode("f5ff"), 2e3);
  }

  return (
    <>
      <style>{require("bundle-text:./styles/index.scss")}</style>
      <Show when={installed()}>
        <div class="installed-alert">
                    <span class="installed-alert__icon">
                        {/*@ts-ignore*/}
                      <builderall-icon code="builderall-logo"></builderall-icon>
                    </span>
          <span class="installed-alert__description">
                        {translations["global-script--installed-description"]}
                    </span>
        </div>
      </Show>
      {/*@ts-ignore*/}
      <builderall-loader loading={!source()}>
        <div class="content__textarea">
          <textarea class="textarea" rows={4} value={source()} readOnly/>
          <button class="button__copy" onClick={copyScript}>
            {/*@ts-ignore*/}
            <builderall-icon code={iconCode()}/>
          </button>
        </div>
        {/*@ts-ignore*/}
      </builderall-loader>
    </>
  );
});
