export function proxyURL(token: string, slug: string, queryParams: { [_: string]: string } = {}): string {
    const baseURL = process.env.OFFICE_API;

    const params = new URLSearchParams();
    params.set("w_authentication", token);

    for (const k in queryParams) {
        params.set(k, queryParams[k]);
    }

    return `${baseURL}/v1/wrapper/proxy-redirect/${slug}?${params.toString()}`;
}

export async function sendRequest(token: string, path: string, options: RequestInit = {}, isOfficeAPI: boolean = true): Promise<any> {
    const baseURL = isOfficeAPI ? process.env.OFFICE_API : process.env.GLOBAL_FILE_API;

    const url = `${baseURL}/v1/${path}`;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Accept", "application/json");

    const response = await fetch(url, {headers, cache: "no-store", ...options});

    const {data, success, message} = await response.json();
    if (!success) {
        throw new Error(message);
    }

    return data;
}