import {customElement} from "solid-element";
import {setTranslations, translations} from "../../services/context";
import {getTranslations} from "../../services/translation";
import { createSignal } from "solid-js";

// Insert WebComponent to Website Builder
// TAG: ba-domain-purchase-home -> builderall-open-domain-purchase
// var configBaDomainPurchase = {
//     placeholder: 'Find the perfect domain for your business',
//     buttonName: 'Search',
//     inputBackground: '#fff',
//     inputColor: '#4d5d71',
//     buttonColor: '#fff',
//     buttonBackground: 'transparent',
//     borderRadius: '16px',
//     lang: 'en',
//     font: 'Montserrat'
// };
// ATTENTION: Se essa variável estiver descomentada os parâmetros do Website Builder não irão funcionar

const [searchFirstDomainValue, setSearchFirstDomainValue] = createSignal(null);
const [validateSearchData, setValidateSearchData] = createSignal(false);

let baEnomWebComponent = document.createElement('builderall-enom');
document.addEventListener("DOMContentLoaded", function(event) {
    let body = document.querySelector('body');
    let baDomainPurchases = document.querySelectorAll('*[data-tag*="ba-domain-purchase-home"]');

    if(baDomainPurchases.length > 0){
        getTranslations(configBaDomainPurchase.lang).then((r => setTranslations(r)));
        baEnomWebComponent.setAttribute('show', 'false');
        baEnomWebComponent.setAttribute('isfromhomepage', 'true');
        baEnomWebComponent.setAttribute('domain', null);
        baEnomWebComponent.style.zIndex = '9999';
        baEnomWebComponent.style.position = 'absolute';
        body.appendChild(baEnomWebComponent);
        
        baDomainPurchases.forEach(function(baDomainPurchase) {
            let domainPurchaseWebComponent = document.createElement('builderall-open-domain-purchase');
            baDomainPurchase.insertAdjacentElement('afterend', domainPurchaseWebComponent);
            baDomainPurchase.remove();
        });
    
        var script  = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', 'https://cdn.iubenda.com/iubenda.js');
    
        var script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.innerHTML = 'var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "kfytF6FLFypCygBMj3uYPUbyIyT5ozIK"}]);';
    
        var script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.src = 'https://cdn.iubenda.com/cons/iubenda_cons.js';
        script2.async = true;
    
        document.body.appendChild(script);
        document.body.appendChild(script1);
        document.body.appendChild(script2);
    }
});

customElement("builderall-open-domain-purchase", props => {

    const changeSearchDomainInput = (event) => {
        setSearchFirstDomainValue(event.target.value);
        const newValue = event.target.value.replace(/\s/g, "");
        setSearchFirstDomainValue(newValue);
    };

    function handleEnterKey(event) {
        if(event.key === "Enter"){
            openEnum();
        }
    }

    function openEnum(){
        if(searchFirstDomainValue()){
            baEnomWebComponent.setAttribute("show", "true");
            baEnomWebComponent.setAttribute('domain', searchFirstDomainValue());
            setValidateSearchData(false);
        } else {
            setValidateSearchData(true);
        }
    }

    return (
        <>
            <style>{require("bundle-text:./styles/domainPurchaseHome.scss")}</style>
            <div class="domain-purchase-home">
                <input 
                    type="text" 
                    style={{
                        'background-color': configBaDomainPurchase.inputBackground, 
                        'color': configBaDomainPurchase.inputColor,
                        'border-radius': configBaDomainPurchase.borderRadius,
                        'font-family': configBaDomainPurchase.font
                    }}
                    classList={{'haserror': validateSearchData()}}
                    placeholder={configBaDomainPurchase.placeholder} 
                    value={searchFirstDomainValue()} 
                    onInput={changeSearchDomainInput} 
                    on:keydown={handleEnterKey}
                />
                <button 
                    onClick={() => openEnum()}
                    style={{
                        'border-color': configBaDomainPurchase.buttonColor, 
                        'color': configBaDomainPurchase.buttonColor,
                        'border-radius': configBaDomainPurchase.borderRadius,
                        'background-color': configBaDomainPurchase.buttonBackground,
                        'font-family': configBaDomainPurchase.font
                    }} 
                >
                    {configBaDomainPurchase.buttonName}
                </button>
            </div>
        </>
    );
});