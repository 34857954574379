








module.exports = {
  "de": () => import("../locales/de.json"),
  "en": () => import("../locales/en.json"),
  "es": () => import("../locales/es.json"),
  "fr": () => import("../locales/fr.json"),
  "he": () => import("../locales/he.json"),
  "it": () => import("../locales/it.json"),
  "nl": () => import("../locales/nl.json"),
  "pl": () => import("../locales/pl.json"),
  "pt": () => import("../locales/pt.json")
}