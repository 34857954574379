import {For} from "solid-js";

export default function LegacyToolsModal(props) {
    return (
        <div class="modal legacy-modal">
            <div class="modal-header">
                <h2 class="modal-title">Legacy</h2>
            </div>
            <div class="modal-content">
                <For each={props.tools}>
                    {t => (
                        <a href={t.href} target={t.target}>{t.title}</a>
                    )}
                </For>
            </div>
        </div>
    );
}